<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-25 10:52:42
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-10-09 14:20:31
-->
<template>
  <div class="registerPage">
    <header v-if="!isNext">忘记密码</header>
    <header v-else>设置密码</header>

    <el-form
      :model="resetPassForm"
      :rules="resetPassFormRules"
      ref="resetPassForm"
      class="form_box"
    >
      <template v-if="!isNext">
        <el-form-item prop="mobile">
          <el-input v-model="resetPassForm.mobile" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="imgCode">
          <el-input v-model="resetPassForm.imgCode" placeholder="请输入验证码">
            <el-button slot="append" class="graphic-code" @click="getLoginCode">
              <img v-if="codeImg != ''" :src="codeImg" alt="验证码" class="imgCode" />
              <span v-else class="imgCode">点击重新加载</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="resetPassForm.code" placeholder="请输入动态码">
            <template slot="append">
              <el-link type="info" v-if="isOn">({{count}}秒)后重新发送</el-link>
              <el-link type="primary" @click="handleCode" v-else>发送验证码</el-link>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item class="buttons">
          <el-button type="primary" @click="handleNext" :loading="loginLoading">下一步</el-button>
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item prop="password">
          <el-input type="password" v-model="resetPassForm.password" placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item prop="pass2">
          <el-input type="password" v-model="resetPassForm.pass2" placeholder="请确认登录密码"></el-input>
        </el-form-item>
        <el-form-item class="buttons">
          <el-button type="warning" @click="isNext = false">上一步</el-button>
          <el-button
            type="primary"
            @click="submitForm('resetPassForm')"
            :loading="loginLoading"
          >{{loginText}}</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import api from "@/api/list";
import { apiList } from "@/api/list";
import { rsaEncrypt } from "@/utils/rsa";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入登录密码"));
      } else {
        if (this.resetPassForm.pass2 !== "") {
          this.$refs.resetPassForm.validateField("pass2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入登录密码"));
      } else if (value !== this.resetPassForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loginLoading: false,
      loginText: "设置",
      isNext: false,
      count: 60,
      codeImg: "",
      isOn: false,
      resetPassForm: {
        mobile: "",
        code: "",
        token: "",
        imgCode: "",
        imgToken: "",
        password: "",
        pass2: "",
      },
      resetPassFormRules: {
        mobile: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "手机号应该为11位数字",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入动态码", trigger: "blur" }],
        imgCode: [
          { required: true, message: "请输入验证码!", trigger: "blur" },
          { validator: this.validateInputCode, trigger: "blur" },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        pass2: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted: function () {
    this.getLoginCode();
  },
  watch: {
    codeImg: function () {},
  },
  methods: {
    handleNext() {
      this.$refs["resetPassForm"].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          api
            .forgetPassword(
              {
                username: this.resetPassForm.mobile,
                code: this.resetPassForm.code,
                token: this.resetPassForm.token,
              },
              { method: "post", subUrl: 0 }
            )
            .then((res) => {
              this.loginLoading = false;
              if (res.data == "true") {
                this.isNext = true;
              } else {
                this.$message({
                  showClose: true,
                  message: res.message,
                  type: "warning",
                });
              }
            });
        }
      });
    },
    getLoginCode() {
      this.resetPassForm.imgToken = this.getRandomCode(32);
      api.getImgCode({ token: this.resetPassForm.imgToken }).then(
        (response) => {
          this.codeImg =
            process.env.VUE_APP_BASE_API +
            apiList.getImgCode +
            "?token=" +
            this.resetPassForm.imgToken;
        },
        (error) => {
          this.codeImg = "";
        }
      );
    },
    getRandomCode(length) {
      if (length > 0) {
        var data = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    validateInputCode(rule, value, callback) {
      api
        .validateCode({ token: this.resetPassForm.imgToken, code: value })
        .then((response) => {
          if (!value || response.data) {
            callback();
          } else {
            callback("您输入的验证码不正确!");
          }
        });
    },
    handleCode() {
      if (this.resetPassForm.mobile == "") {
        this.$message({
          showClose: true,
          message: "请输入手机号码",
          type: "warning",
        });
        return false;
      }

      if (this.resetPassForm.mobile.length !== 11) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号码",
          type: "warning",
        });
        return false;
      }

      api.sendSmsCode({ mobile: this.resetPassForm.mobile }).then((res) => {
        this.resetPassForm.token = res.data;
        this.isOn = true;
        let that = this;
        let interval = window.setInterval(function () {
          --that.count;
          if (that.count < 0) {
            that.isOn = false;
            that.count = 60;
            window.clearInterval(interval);
          }
        }, 1000);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          this.loginText = "设置中";

          api
            .setForgetPassword(
              {
                code: this.resetPassForm.code,
                mobile: this.resetPassForm.mobile,
                token: this.resetPassForm.token,
                password: rsaEncrypt(this.resetPassForm.password),
              },
              { method: "put" }
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "密码设置成功",
                type: "success",
              });
              this.$router.push("/login");
              this.loginLoading = false;
              this.loginText = "设置";
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.registerPage {
  width: 1210px;
  margin: 20px auto;
  background: url(../../assets/register_bg.png) right bottom no-repeat #fff;
  padding: 60px 130px;
  header {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }
}
.form_box {
  width: 294px;
  height: 280px;
  margin: 0 auto;
  /deep/ .el-link--info {
    .el-link--inner {
      color: #999;
    }
  }
  /deep/ .el-input__inner {
    background: #f3f3f3;
    border-color: #f3f3f3;
  }

  /deep/ .el-input-group__append {
    background: #f3f3f3;
    border: none;
  }

  .graphic-code {
    position: relative;
    top: 7px;
    padding: 0;
  }

  .imgCode {
    display: block;
    height: 38px;
    line-height: 38px;
    min-width: 120px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #999;
  }
}
.buttons {
  /deep/ .el-button {
    width: 48%;
    margin: 42px 2px 12px;

    &:only-child {
      display: block;
      width: 100%;
      margin: 42px 0 12px;
    }

    &.el-button--warning {
      background: #ffbf25;
      border-color: #ffbf25;
    }

    &.el-button--primary {
      background: #5f6eea;
      border-color: #5f6eea;
    }
  }
}

/deep/ input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
}
</style>